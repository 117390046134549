export const environment = {
  production: false,
  version: "3680c305cf870b1df31aea5c785e7a4d30aaeff9", //will be replaced via the build script
  versionName: "5.1.0", // TODO: increment upon each deployment
  baseUrl: "https://demo-api.applause.school",
  // baseUrl: "http://192.168.1.90:8000", //  change back
  guiBaseUrl: "https://demo.applause.school", // NO trailing slash
  features: {
    autoplay: false,
  },
  serviceWorkerEnabled: true,
  logoutOnUpgrade: true,
  matomoUrl: "https://demo-ma.applause.school/",
  apiDateFormat: "yyyy-MM-dd HH:mm:ss",
  terms:
    "https://drive.google.com/file/d/1ythsvPZQtIYFnjdmpihjAvuUNTo_mqD8/view?usp=sharing",
  gdpr: "https://applaus.schule/datenschutz/",
  stripeApiKey:
    "pk_test_51LO1UfC5ZPKL8EaZBf0fz5fxJrZhkiOGdkpJQENWd2v675nZ5ROpchhTJotK5e89PY7MfhReoaqjNZmzlaQf0kBV00phMzHDZn",
  googleClientId:
    "757076138316-jnrlatmbg54haij33a7h56gu5gvm01ts.apps.googleusercontent.com",
};
